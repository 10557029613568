/**
 * Fetcher User
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FUser extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/user/', '/Home/User/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取单个会员详情（会员列表-会员详情）
    getUserDetail(id) {
        const url = this.spellURL('getUserDetail', 'user_detail');
        const send = {
            'user_id': id,
        };
        return this.post(url, send);
    }

    // 绑定会员 OpenId（会员列表-会员详情）
    saveBindUserOpenId(userSet) {
        const url = this.spellURL('saveBindUserOpenId', 'bind_wx');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 解绑会员 OpenId（会员列表-会员详情）
    saveUnbindUserOpenId(userSet) {
        const url = this.spellURL('saveUnbindUserOpenId', 'unbind_wx');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 绑定会员用气编号（会员列表-会员详情）
    saveBindUserSafeCode(userSet) {
        const url = this.spellURL('saveBindUserSafeCode', 'bind_safecheck');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 解绑会员用气编号（会员列表-会员详情）
    saveUnbindUserSafeCode(userSet) {
        const url = this.spellURL('saveUnbindUserSafeCode', 'lift_bind_safe');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 绑定会员推荐来源推荐码（会员列表-会员详情）
    saveBindUserRecommendCode(userSet) {
        const url = this.spellURL('saveBindUserRecommendCode', 'bind_recommend');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 生成会员推荐码（会员列表-会员详情）
    getNewUserRecommendCode(user) {
        const url = this.spellURL('getNewUserRecommendCode', 'make_recommend');
        const send = {
            ...this.transKeyName('underline', user),
        };
        return this.post(url, send);
    }

    // 会员销户（会员列表-会员详情）
    saveUserCancellation(cancellation) {
        const url = this.spellURL('saveUserCancellation', 'cancellation_user');
        const send = {
            ...this.transKeyName('underline', cancellation),
        };
        return this.post(url, send);
    }

    // 获取单个会员消费列表（会员列表-会员详情）
    getUserConsumption(search, pagination, sorter) {
        const url = this.spellURL('getUserConsumption', 'user_expense');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取单个会员坏信用记录（会员列表-会员详情）
    getUserBadCredit(search, pagination, sorter) {
        const url = this.spellURL('getUserBadCredit', 'user_credit');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取单个会员交押金记录（会员列表-会员详情）
    getUserDepositList(search, pagination, sorter) {
        const url = this.spellURL('getUserDepositList', 'user_deposit');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send, [
            ['', 'deposit', 'ext'],
        ]);
    }

    // 获取会员可交押金的产品列表（会员列表-会员详情）
    getUserDepositOptionList(userSet) {
        const url = this.spellURL('getUserDepositOptionList', 'depositList');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 保存单个会员押金记录（会员列表-会员详情）
    saveUserDepositRecord(depositData) {
        const url = this.spellURL('saveUserDepositRecord', 'add_deposit');
        const { datas, ...msgs } = depositData;
        const send = {
            datas: datas.map(data => ({
                id: data.id,
                'rule_id': data.ruleId,
                num: data.num,
                deposit: data.deposit,
                singleDeposit: data.money,
            })),
            ...this.transKeyName('underline', msgs),
        };
        return this.post(url, send);
    }

    // 保存单个会员带瓶入户（会员列表-会员详情）
    saveUserBottleEnter(depositData) {
        const url = this.spellURL('saveUserBottleEnter', 'add_self_deposit');
        const send = {
            ...this.transKeyName('underline', depositData),
        };
        return this.post(url, send);
    }

    // 获取会员押金记录详情（会员列表-会员详情）
    getUserDepositDetail(search) {
        const url = this.spellURL('getUserDepositDetail', 'deposit_detail');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取会员押金可关联的气瓶（会员列表-会员详情）
    getUserDepositBottles(search) {
        const url = this.spellURL('getUserDepositBottles', 'takeDepositList');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存会员押金扣除（会员列表-会员详情）
    saveUserDepositDeduction(deduction) {
        const url = this.spellURL('saveUserDepositDeduction', 'take_deposit');
        const send = {
            ...this.transKeyName('underline', deduction),
        };
        return this.post(url, send);
    }

    // 保存气瓶使用费已交费
    saveBottleUseFeePayed(payed) {
        const url = this.spellURL('saveBottleUseFeePayed', 'usecharge_payed');
        const send = {
            ...payed,
        };
        return this.post(url, send);
    }

    // 获取单个会员充值记录（会员列表-会员详情）
    getUserRechargeList(search, pagination, sorter) {
        const url = this.spellURL('getUserRechargeList', 'user_rechange');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取会员充值策略（会员列表-会员详情）
    getUserRechargeStrategy(search) {
        const url = this.spellURL('getUserRechargeStrategy', 'chongzhi');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存会员充值记录（会员列表-会员详情）
    saveUserRechargeRecord(recharge) {
        const url = this.spellURL('saveUserRechargeRecord', 'add_rechange');
        const send = {
            ...this.transKeyName('underline', recharge),
            'rechangevalue': recharge.value,
        };
        delete send.value;
        return this.post(url, send);
    }

    // 获取会员充值详情（会员列表-会员详情）
    getUserRechargeDetail(recharge) {
        const url = this.spellURL('getUserRechargeDetail', '../Money/chongzhi_detail');
        const send = {
            ...recharge,
        };
        return this.post(url, send);
    }

    // 获取会员充值退款可退款信息（会员列表-会员详情）
    getUserRechargeRefund(recharge) {
        const url = this.spellURL('getUserRechargeRefund', '../Money/refund');
        const send = {
            ...recharge,
        };
        return this.post(url, send);
    }

    // 保存会员退款（会员列表-会员详情）
    saveUserRechargeRefund(refund) {
        const url = this.spellURL('saveUserRechargeRefund', '../Money/pay_refund');
        const send = {
            ...this.transKeyName('underline', refund),
        };
        return this.post(url, send);
    }

    // 获取单个会员优惠券列表（会员列表-会员详情）
    getUserCouponList(search, pagination, sorter) {
        const url = this.spellURL('getUserCouponList', 'user_voucher');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send, [
            ['', 'number', 'ext'],
        ]);
    }

    // 获取会员可派券优惠规则（会员列表-会员详情）
    getUserCouponRules(search) {
        const url = this.spellURL('getUserCouponRules', '../Money/quanType');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存给会员的派券（会员列表-会员详情）
    saveUserAddCoupon(coupon) {
        const url = this.spellURL('saveUserAddCoupon', '../Money/add_quan');
        const send = {
            ...this.transKeyName('underline', coupon),
        };
        return this.post(url, send);
    }

    // 保存给会员的优惠券标记已使用（会员列表-会员详情）
    saveUserCouponBeenUsed(coupon) {
        const url = this.spellURL('saveUserCouponBeenUsed', 'use_voucher');
        const send = {
            ...this.transKeyName('underline', coupon),
        };
        return this.post(url, send);
    }

    // 获取用户 vip 价格列表（会员详情）
    getUserVipPriceList(search, pagination, sorter) {
        const url = this.spellURL('getUserVipPriceList', 'user_vipprice');
        const send = {
            ...search,
            'user_id': search.id,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        delete send.id;
        return this.post(url, send);
    }

    // 保存添加会员协议价（会员详情）
    saveAddUserVipPrice(price) {
        const url = this.spellURL('saveAddUserVipPrice', 'add_vipprice');
        const send = {
            ...this.transKeyName('underline', price),
        };
        return this.post(url, send);
    }

    // 保存给会员的 vip 价格设置无效（会员列表-会员详情）
    saveUserVipPriceStatus(price) {
        const url = this.spellURL('saveUserVipPriceStatus', 'update_vipprice');
        const send = {
            ...this.transKeyName('underline', price),
        };
        return this.post(url, send);
    }

    // 获取单个会员安检记录列表（会员列表-会员详情）
    getUserSafeCheckList(search, pagination, sorter) {
        const url = this.spellURL('getUserSafeCheckListV2', 'user_safecheck');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取会员安检详情（会员列表-会员详情）
    getUserSafeCheckDetail(safeCheck) {
        const url = this.spellURL('getUserSafeCheckDetail', '../Security/security_check_item');
        const send = {
            ...this.transKeyName('underline', safeCheck),
        };
        return this.post(url, send);
    }

    // 获取单个会员用气合同列表（会员列表-会员详情）
    getUserContractLog(search, pagination, sorter) {
        const url = this.spellURL('getUserContractLog', 'user_contract_log');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'user_id': search.id,
        };
        delete send.id;
        return this.post(url, send);
    }

    // 保存会员用气合同（会员列表-会员详情）
    saveUserContractLog(contract) {
        const url = this.spellURL('saveUserContractLog', 'add_contract');
        const send = {
            ...this.transKeyName('underline', contract),
        };
        return this.post(url, send);
    }

    // 删除会员用气合同（会员列表-会员详情）
    saveRemoveContract(contract) {
        const url = this.spellURL('saveRemoveContract', 'del_contract');
        const send = {
            ...this.transKeyName('underline', contract),
        };
        return this.post(url, send);
    }

    // 获取单个会员在用气瓶（会员列表-会员详情）
    getUserBottles(search, pagination, sorter) {
        const url = this.spellURL('getUserBottles', 'used_gangping');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'user_id': search.id,
        };
        delete send.id;
        return this.post(url, send);
    }

    // 保存设置用户丢瓶（会员列表-会员详情）
    saveUserBottleMissing(missing) {
        const url = this.spellURL('saveUserBottleMissing', 'used_gangping_edit');
        const send = {
            ...this.transKeyName('underline', missing),
        };
        return this.post(url, send);
    }

    // 获取气瓶丢瓶信息（会员列表-会员详情）
    getUserBottleMissing(bottle) {
        const url = this.spellURL('getUserBottleMissing', 'used_gangping_info');
        const send = {
            ...this.transKeyName('underline', bottle),
        };
        return this.post(url, send);
    }

    // 保存气瓶丢瓶已处理（会员列表-会员详情-在用气瓶）
    saveUserBottleMissingSolve(solve) {
        const url = this.spellURL('saveUserBottleMissingSolve', 'solve_error');
        const send = {
            ...this.transKeyName('underline', solve),
        };
        return this.post(url, send);
    }

    // 获取气瓶临时欠瓶记录（会员列表-会员详情）
    getUserBottleArrears(search, pagination, sorter) {
        const url = this.spellURL('getUserBottleArrears', 'bottle_arrears_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        delete send.id;
        return this.post(url, send);
    }

    // 获取单个会员推荐的新会员列表（会员列表-会员详情）
    getUserRecommendList(search, pagination, sorter) {
        const url = this.spellURL('getUserRecommendList', 'user_recommend_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'user_id': search.id,
        };
        delete send.id;
        return this.post(url, send);
    }

    // 获取单个会员操作日志（会员列表-会员详情）
    getUserOperationList(search, pagination, sorter) {
        const url = this.spellURL('getUserOperationList', 'operate_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'user_id': search.id,
        };
        delete send.id;
        return this.post(url, send);
    }

    // 通过手机获取会员信息（添加订单）
    getUserByTel(search) {
        const url = this.spellURL('getUserByTel', 'get_by_tel');
        const send = {
            ...search,
        };
        return this.post(url, send);
    }

    // 获取会员信息（会员列表-编辑会员）
    getUserInfo(search) {
        const url = this.spellURL('getUserInfo', 'userInfo');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send, [
            ['user', 'realname', 'name'],
            ['user', 'shop', 'store'],
        ]);
    }

    // 保存会员信息（会员列表-编辑会员）
    saveUserInfo(info) {
        const url = this.spellURL('saveUserInfo', 'updateUserInfo');
        const send = this.trimEndsSpace({
            ...info,
            'allow_monthly': info.allowMonthly,
            'user_id': info.userId,
            'card_no': info.cardNo,
            'calc_residual': info.calcResidual,
            'salesman_id': info.salesmanId,
            'type_id': info.typeId,
            'arrears_money_limit': info.arrearsMoneyLimit,
            'station_id': info.stationId,
            'credit_code': info.creditCode,
            'register_name': info.registerName,
            'register_address': info.registerAddress,
        });
        delete send.userId;
        delete send.cardNo;
        delete send.calcResidual;
        delete send.salesmanId;
        delete send.typeId;
        delete send.stationId;
        delete send.creditCode;
        delete send.registerName;
        delete send.registerAddress;
        return this.post(url, send);
    }

    // 保存会员信息（添加订单）
    saveUserInfoForOrder(info) {
        const url = this.spellURL('saveUserInfo', 'changeUserInfo');
        const send = this.trimEndsSpace({
            ...info,
            'user_id': info.userId,
            'card_no': info.cardNo,
            'type_id': info.typeId,
        });
        delete send.userId;
        delete send.cardNo;
        delete send.typeId;
        return this.post(url, send);
    }

    // 获取所有会员列表（会员列表）
    getUsersList(search, pagination, sorter) {
        const url = this.spellURL('getUsersList', 'user_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: ({
                floor: 'floor',
                typeName: 'type_id',
                gradeName: 'grade_id',
                save: 'save',
                cost: 'cost',
                jifen: 'jifen',
                deposit: 'deposit',
                depositPercent: 'deposit_percent',
                gasPeriod: 'gas_period',
                lastGas: 'last_gas',
            })[sorter.field],
            sortOrder: sorter.order,
            'pcc': search.pcc?.replace(/,/g, '/'),
            'type_id': search.typeId,
            'grade_id': search.gradeId,
            'bind_safe': search.bindSafe,
            'min_rechange': search.rechangeRange?.split(',')[0],
            'max_rechange': search.rechangeRange?.split(',')[1],
            'min_cost': search.costRange?.split(',')[0],
            'max_cost': search.costRange?.split(',')[1],
            'min_gas_period': search.gasPeriodRange?.split(',')[0],
            'max_gas_period': search.gasPeriodRange?.split(',')[1],
            'min_dep_per': search.depositPerRange?.split(',')[0],
            'max_dep_per': search.depositPerRange?.split(',')[1],
            'min_floor': search.floorRange?.split(',')[0],
            'max_floor': search.floorRange?.split(',')[1],
            'datetime': search.datetime?.replace(',', ' - '),
            'consume_date': search.consumeDate?.replace(',', ' - '),
            'station_id': search.stationId,
        };
        delete send.typeId;
        delete send.gradeId;
        delete send.bindSafe;
        delete send.rechangeRange;
        delete send.costRange;
        delete send.gasPeriodRange;
        delete send.depositPerRange;
        delete send.floorRange;
        delete send.consumeDate;
        delete send.stationId;
        return this.post(url, send);
    }

    // 保存会员楼层电梯（会员列表）
    saveUserFloorLift(userSet) {
        const url = this.spellURL('saveUserFloorLift', 'api_user_floor_lift');
        const send = {
            ...userSet,
        };
        return this.post(url, send);
    }

    // 保存会员类型等级（会员列表）
    saveUserTypeGrade(userSet) {
        const url = this.spellURL('saveUserTypeGrade', 'api_user_type_grade');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 保存会员积分兑换（会员列表）
    saveUserScoreExchange(userSet) {
        const url = this.spellURL('saveUserScoreExchange', 'api_pay_jifen.html');
        const send = {
            ...this.transKeyName('underline', userSet),
        };
        return this.post(url, send);
    }

    // 保存会员门店（会员列表）
    saveUserStore(store) {
        const url = this.spellURL('saveUserStore', 'user_store');
        const send = {
            ...this.transKeyName('underline', store),
        };
        return this.post(url, send);
    }

    // 获取会员列表-导出地址（气瓶档案）
    getUserListExportUrl(search, type) {
        let serSuffix = '';
        if (type === 'userList') {
            serSuffix = 'export_user';
        } else if (type === 'deposit') {
            serSuffix = 'export_deposit';
        } else if (type === 'recharge') {
            serSuffix = 'export_rechange';
        }
        const url = this.spellURL('../common/defaultExportUrl', serSuffix);
        const send = {
            ...search,
        };
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return Promise.resolve({
                url: this.parseParamUrl(url, send),
            });
        }
    }

    getUsersNewerStatistic(search) {
        const url = this.spellURL('getUsersNewerStatistic', 'new_vip_statistic');
        const send = {
            ...search,
            'datetime': search.dateTime?.replace(',', ' - '),
            'type': 1,
        };
        return this.post(url, send, [
            ['', 'vips', 'rows'],
        ]);
    }

    // 获取所有会员积分兑换记录（积分兑换记录）
    getUsersScoreExchangeList(search, pagination, sorter) {
        const url = this.spellURL('getUsersScoreExchangeList', 'jifen_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'datetime': search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取所有会员的分布分析（会员分布）
    getUsersDistribution(type = 'all') {
        const url = this.spellURL('getUsersDistribution', 'user_duration');
        // 开发模式
        if (Fetcher.inDevMod) {
            const send = {};
            return this.post(url, send).then(res => {
                if (type === 'all') {
                    return res;
                } else {
                    return {
                        rows: res[type],
                    };
                }
            });
        }
        // 线上模式，合并请求
        else {
            if (type === 'all') {
                const team = [
                    this.post(url, { type: 'store' }),
                    this.post(url, { type: 'area' }),
                    this.post(url, { type: 'type' }),
                    this.post(url, { type: 'grade' }),
                    this.post(url, { type: 'expense' }),
                ];
                return Promise.all(team).then(resList => {
                    return {
                        store: resList[0].rows,
                        area: resList[1].rows,
                        type: resList[2].rows,
                        grade: resList[3].rows,
                        expense: resList[4],
                    };
                });
            } else {
                return this.post(url, { type });
            }
        }
    }

    // 获取会员地图（会员地图）
    getUsersMap(search, pagination) {
        const url = this.spellURL('getUsersMap', 'user_map');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send, [
            ['', 'totalpage', 'totalPage'],
        ]);
    }

    // 获取超期会员列表（超期会员）
    getUsersOverdue(search, pagination, sorter) {
        const url = this.spellURL('getUsersOverdue', 'abnormal_user');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'datetime': search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取超期会员-导出地址（超期会员）
    getUsersOverdueExportUrl(search) {
        const url = this.spellURL('../common/defaultExportUrl', '../User/export_abnormal');
        const send = {
            ...search,
            'date': search.datetime?.replace(',', ' - '),
        };
        delete send.datetime;
        if (Fetcher.inDevMod) {
            return this.post(url, send).then(res => ({ url: res.file }));
        } else {
            return Promise.resolve({
                url: this.parseParamUrl(url, send),
            });
        }
    }

    // 获取会员状态列表（会员状态跟踪）
    getUsersStatusTrack(search, pagination, sorter) {
        const url = this.spellURL('getUsersStatusTrack', 'user_state_track');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取会员类型列表（会员类型）
    getUsersType(search) {
        const url = this.spellURL('getUsersType', 'user_type');
        const send = {
            ...search,
            page: 1,
            limit: 200,
        };
        return this.post(url, send, [
            ['rows.[]', 'default', 'isDefault'],
        ]);
    }

    // 获取会员类型列表（筛选项）
    getUsersTypeAsSelect(showType) {
        const url = this.spellURL('getUsersTypeFilter', 'user_type');
        const send = {
            page: 1,
            limit: 100,
        };
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                const parentName = ['', '工商', '居民', '其他'];
                return res.rows.map(item => {
                    const { id, name, mainTypeId } = item;
                    if (showType === 'showScale') {
                        return {
                            id, name,
                            parent: parentName[mainTypeId],
                            scale: item.scale,
                        };
                    }
                    if (showType === 'showParent') {
                        return {
                            id, name, mainTypeId,
                            explain: parentName[mainTypeId],
                        };
                    } else {
                        return { id, name };
                    }
                });
            });
    }

    // 保存会员类型设置（会员类型）
    saveUsersTypeSet(typeSet) {
        const url = this.spellURL('saveUsersTypeSet', 'user_type');
        const send = {
            ...typeSet,
        };
        return this.post(url, send);
    }

    // 保存会员默认类型（会员类型）
    saveUsersDefaultType(typeSet) {
        const url = this.spellURL('saveUsersDefaultType', 'user_type');
        const send = {
            ...typeSet,
        };
        return this.post(url, send);
    }

    // 保存按会员类型分的欠瓶控制设置（会员类型）
    saveUserTypeLackBottle(lackData) {
        const url = this.spellURL('saveUserTypeLackBottle', 'save_deposit_scale');
        const send = {
            params: lackData,
        };
        return this.post(url, send);
    }

    // 获取会员等级列表（会员会员等级）
    getUsersGrade(search) {
        const url = this.spellURL('getUsersGrade', 'user_grade');
        const send = {
            ...search,
            page: 1,
            limit: 100,
        };
        return this.post(url, send, [
            ['rows.[]', 'default', 'isDefault'],
        ]);
    }

    // 获取会员等级列表（筛选项）
    getUsersGradeAsSelect() {
        const url = this.spellURL('getUsersGradeFilter', 'userGradeField');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                    discount: parseInt(item.cfDiscount),
                }));
            });
    }

    // 保存会员等级（会员会员等级）
    saveUsersGradeSet(gradeSet) {
        const url = this.spellURL('saveUsersGradeSet', 'user_grade');
        const send = {
            ...this.transKeyName('underline', gradeSet),
        };
        return this.post(url, send);
    }

    // 保存会员默认等级（会员会员等级）
    saveUsersDefaultGrade(gradeSet) {
        const url = this.spellURL('saveUsersDefaultGrade', 'user_grade');
        const send = {
            ...gradeSet,
        };
        return this.post(url, send);
    }

    // 获取用户等级商品价（会员等级）
    getUsersProductList(search) {
        const url = this.spellURL('getUsersProductList', 'viewProducts');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取所有会员短信列表（验证短信）
    getSMSList(search, pagination, sorter) {
        const url = this.spellURL('getSMSList', 'sms_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'time': search.time?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取已销户会员（销户会员）
    getCancellationUsers(search, pagination, sorter) {
        const url = this.spellURL('getCancellationUsers', 'user_cancellation_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'time': search.time?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取会员气瓶收费项目明细（添加订单）
    getUserBottleUseMoney(user) {
        const url = this.spellURL('getUserBottleUseMoney', '../order/add_use_charge');
        const send = {
            ...this.transKeyName('underline', user),
        };
        return this.post(url, send);
    }

    // 获取会员折扣价列表（折扣价列表）
    getVipPriceList(search, pagination, sorter) {
        const url = this.spellURL('getVipPriceList', 'vipprice_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

}

export const $fetchUser = new FUser();

