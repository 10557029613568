/**
 * Fetcher Staff
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree.json';

export class FStaff extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/staff/', '/Home/Worker/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取员工气瓶资产（员工详情）
    getStaffBottleList(search, pagination, sorter) {
        const url = this.spellURL('getStaffBottleList', 'employee_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            type: 'bottle',
        };
        return this.post(url, send, [
            ['', 'count', 'total'],
            ['', 'products', 'rows'],
        ]);
    }

    // 获取员工气瓶资产详情（员工详情）
    getStaffBottleDetail(search) {
        const url = this.spellURL('getStaffBottleDetail', 'employee_detail');
        const send = {
            ...this.transKeyName('underline', search),
            type: 'bottle_detail',
        };
        return this.post(url, send);
    }

    // 获取员工气瓶流转列表（员工详情）
    getStaffBottleCirculation(search, pagination, sorter) {
        const url = this.spellURL('getStaffBottleCirculation', 'employee_detail');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            type: 'position',
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send, [
            ['', 'count', 'total'],
            ['', 'exchanges', 'rows'],
        ]);
    }

    // 获取员工气瓶流转详情（员工详情）
    getStaffBottleCircleDetail(circle) {
        const url = this.spellURL('getStaffBottleCircleDetail', 'gangping_detail');
        const send = {
            ...circle,
        };
        return this.post(url, send);
    }

    // 获取员工配送订单列表（员工详情）
    getStaffDeliveryList(search, pagination, sorter) {
        const url = this.spellURL('getStaffDeliveryList', '../Order/order_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'datetime': search.datetime?.replace(',', ' - '),
        };
        return this.post(url, send, [
            ['', 'count', 'total'],
            ['', 'orders', 'rows'],
        ]);
    }

    // 获取员工维修工单列表（员工详情）
    getStaffRepairJobList(search, pagination, sorter) {
        const url = this.spellURL('getStaffRepairJobList', '../Order/repair_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取员工挂职门店列表（员工详情）
    getStaffTemporaryStore(search, pagination, sorter) {
        const url = this.spellURL('getStaffTemporaryStore', 'getExtstores');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取气站全部人员列表（气站人员）
    getStationAllWorkers(search, pagination, sorter) {
        const url = this.spellURL('getStationAllWorkers', 'gaser_list');
        const send = {
            ...search,
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send, [
            ['rows.[]', 'createdate', 'createDate'],
        ]);
    }

    // 获取气站全部人员列表（筛选项）
    getStationAllWorkersAsSelect() {
        const url = this.spellURL('getStationAllWorkersFilter', 'gaser_list');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取销售员列表（销售员列表）
    getSalesManList(search, pagination, sorter) {
        const url = this.spellURL('getSalesManList', '../Sales/salesman_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取销售员列表（筛选项）
    getSalesManListAsSelect(type = 'sales') {
        const url = this.spellURL('getSalesManListFilter', '../Sales/getSalesmanField');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                if (type === 'sales') {
                    return (res.rows || []).map(item => ({
                        id: item.id,
                        name: item.name,
                    }));
                } else if (type === 'staff') {
                    return (res.rows || []).map(item => ({
                        id: item.uid,
                        name: item.name,
                    }));
                }
            });
    }

    // 获取角色类型为销售的员工（筛选项）
    getSalesStaffAsSelect() {
        const url = this.spellURL('getSalesManListFilter', '../Sales/getSaleEmpField');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取销售员详情（销售员列表-销售详情）
    getSalesManDetail(search) {
        const url = this.spellURL('getSalesManDetail', '../Sales/moreSalesmanDetail');
        const send = {
            ...search,
        };
        return this.post(url, send, [
            ['', 'salesman', 'account'],
        ]);
    }

    // 生成新的销售员推荐码（销售员列表-销售详情）
    getNewSalesRecommendCode(manSet) {
        const url = this.spellURL('getNewSalesRecommendCode', '../Sales/make_recommend_code');
        const send = {
            ...manSet,
        };
        return this.post(url, send);
    }

    // 生成新的销售员推荐二维码（销售员列表-销售详情）
    getNewSalesRecommendQRCode(manSet) {
        const url = this.spellURL('getNewSalesRecommendQRCode', '../Sales/make_recommend_qrcode');
        const send = {
            ...manSet,
        };
        return this.post(url, send);
    }

    // 获取销售员客户列表（销售员列表）
    getSalesManCustomers(search, pagination, sorter) {
        const url = this.spellURL('getSalesManCustomers', '../Sales/recommend_members');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 保存批量变更推荐绑定、归属绑定（销售员列表）
    saveChangeBindSalesMan(change) {
        const url = this.spellURL('getNewSalesRecommendQRCode', '../Sales/change_salesman');
        const send = {
            ...this.transKeyName('underline', change),
        };
        return this.post(url, send);
    }

    // 获取配送员账号列表（配送账号列表）
    getDelivererList(search, pagination, sorter) {
        const url = this.spellURL('getDelivererList', 'employee_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: ({
                takeOrder: 'take_order',
            })[sorter.field],
            sortOrder: sorter.order,
            'employee_type': 'dispatch',
        };
        return this.post(url, send);
    }

    // 保存配送员最大气瓶数（配送账号列表）
    saveDelivererMaxBottle(deliverer) {
        const url = this.spellURL('saveDelivererMaxBottle', 'update_employee_bottle_num');
        const send = {
            ...this.transKeyName('underline', deliverer),
        };
        return this.post(url, send);
    }

    // 配送员账号列表（筛选项）
    getDelivererListAsSelect() {
        const url = this.spellURL('getDelivererListFilter', 'getEmployeeList');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取配送员与维修员列表（筛选项）
    getDeliverRepairMenAsSelect() {
        const url = this.spellURL('getDeliverRepairMenFilter', 'getDeliverymanField');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    ...item,
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取司机/押运员列表（司机列表）
    getDriverList(search, pagination, sorter) {
        const url = this.spellURL('getDriverList', 'employee_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
            'employee_type': 'car',
        };
        return this.post(url, send);
    }

    // 获取司机列表（筛选项）
    getDriverListAsSelect() {
        const url = this.spellURL('getDriverListFilter', 'getDriverField');
        const send = {};
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取配送员详情（员工详情）
    getDeliveryDetail(delivery) {
        const url = this.spellURL('getDeliveryDetail', 'employee_detail_info');
        const send = {
            ...delivery,
        };
        return this.post(url, send, [
            ['', 'employee', 'account'],
        ]);
    }

    // 获取配送账号评价（配送评价）
    getDelivererRating(search, pagination, sorter) {
        const url = this.spellURL('getDelivererRating', 'employee_judge');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: ({
                orderCount: 'order_count',
                judgeCnt: 'judge_cnt',
                judgePercent: 'judge_percent',
                avgService: 'avg_service',
                minService: 'min_service',
                maxService: 'max_service',
                countOne: 'count_one',
                countTwo: 'count_two',
                countThree: 'count_three',
                countFour: 'count_four',
                countFive: 'count_five',
            })[sorter.field],
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取配送计数（配送报表）
    getDelivererReport(search, pagination, sorter) {
        const url = this.spellURL('getDelivererReport', 'employee_statistic');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: ({
                workDay: 'work_day',
                order: 'order',
                money: 'money',
                totalProCnt: 'total_pro_cnt',
                avgRecvTime: 'avg_recv_time',
                avgDispTime: 'avg_disp_time',
                floorFee: 'floor_fee',
                dispWeight: 'disp_weight',
                dispHeavy: 'disp_heavy',
                dispGently: 'disp_gently',
                exchHeavy: 'exch_heavy',
                exchGently: 'exch_gently',
            })[sorter.field],
            sortOrder: sorter.order,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取配送重量总计（配送报表）
    getDelivererMonthDispTotal() {
        const url = this.spellURL('getDelivererMonthDispTotal', 'weight_total');
        const send = {};
        return this.post(url, send);
    }

    // 获取配送员配送商品列表（配送报表）
    getDelivererProducts(search) {
        const url = this.spellURL('getDelivererProducts', 'employee_product_detail');
        const send = {
            ...search,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取配送员配送商品详细内容（配送报表）
    getDelivererProductsDetail(search, pagination, sorter) {
        const url = this.spellURL('getDelivererProductsDetail', 'employee_order_info');
        const send = {
            ...search,
            'date': search.date?.replace(',', ' - '),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取配送员配送转移气瓶列表（配送报表）
    getDelivererBottles(search) {
        const url = this.spellURL('getDelivererBottles', 'employee_bottle_detail');
        const send = {
            ...search,
            'date': search.date?.replace(',', ' - '),
        };
        return this.post(url, send);
    }

    // 获取配送统计数据（配送统计）
    getDeliveryStatistic(search) {
        const url = this.spellURL('getDeliveryStatistic', 'employee_total');
        const send = {
            ...search,
            'type': 1,
            'datetime': search.dateTime?.replace(',', ' - '),
        };
        delete send.dateType;
        delete send.dateTime;
        return this.post(url, send, [
            ['', 'avg', 'dates'],
        ]);
    }

    // 获取配送员轨迹（配送轨迹）
    getDelivererTrack() {
        const url = this.spellURL('getDelivererTrack', '../Gangping/get_entitys');
        const send = {
            type: 2,
        };
        return this.post(url, send);
    }

    // 获取员工列表（员工管理）
    getStaffList(search, pagination, sorter) {
        const url = this.spellURL('getStaffList', 'employee_list');
        const send = {
            ...this.transKeyName('underline', search),
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: ({
                takeOrder: 'take_order',
            })[sorter.field],
            sortOrder: sorter.order,
            'employee_type': 'staff',
        };
        return this.post(url, send);
    }

    // 获取安全巡检/定检人员（筛选项）
    getRegularInspectorsAsSelect() {
        const url = this.spellURL('getStaffList', 'employee_list');
        const send = {
            leave: 1,
            'role_id': 7,
            page: 1,
            limit: 900,
            'employee_type': 'staff',
        };
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取充装员（筛选项）
    getFillingWorkerAsSelect() {
        const url = this.spellURL('getStaffList', 'employee_list');
        const send = {
            leave: 1,
            'role_id': 9,
            page: 1,
            limit: 900,
            'employee_type': 'staff',
        };
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取员工信息
    getStaffInfo(staff) {
        const url = this.spellURL('getStaffInfo', 'employee_detail_info');
        const send = {
            ...staff,
            'employee_type': 'dispatch',
        };
        return this.post(url, send);
    }

    // 生成、更新员工二维码
    getNewStaffQRCode(staff) {
        const url = this.spellURL('getNewStaffQRCode', 'generate_qrcode');
        const send = {
            ...this.transKeyName('underline', staff),
        };
        return this.post(url, send);
    }

    // 保存员工信息（员工管理）
    saveStaffInfo(staff) {
        const url = this.spellURL('saveStaffInfo', 'saveStaff');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(staff)),
        };
        return this.post(url, send);
    }

    // 删除（离职）员工账号（员工管理）
    saveDeleteStaff(staff) {
        const url = this.spellURL('saveDeleteStaff', 'api_employee');
        const send = {
            ...staff,
        };
        return this.post(url, send);
    }

    // 获取气站员工（筛选项）
    getStationStaffAsSelect(station) {
        const url = this.spellURL('getStationStaffFilter', '../Area/getStationstaff');
        const send = {
            ...this.transKeyName('underline', station),
        };
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                return (res.rows || []).map(item => ({
                    id: item.id,
                    name: item.name,
                }));
            });
    }

    // 获取员工工卡信息（员工管理）
    getStaffCard(staff) {
        const url = this.spellURL('getStaffCard', 'getStaffCard');
        const send = {
            ...staff,
        };
        return this.post(url, send);
    }

    // 保存员工从业限制
    saveStaffEmployLimit(limit) {
        const url = this.spellURL('saveStaffEmployLimit', 'saveLimit');
        const send = {
            ...this.transKeyName('underline', limit),
        };
        return this.post(url, send);
    }

}

export const $fetchStaff = new FStaff();

